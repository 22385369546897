/* @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500&display=swap'); */
@import url('../font/stylesheet.css');

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
html {
    scroll-behavior: smooth;
  }
.spinner-container {
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    width: 100%;
    z-index: 9;
    /* border-radius: 60px; */
}

.loading-spinner {
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #19A85E;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    top: 46%;
    left: 46%;
    transform: translate(-50%, -50%);
}

body {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300 !important;
    background-color: #E9E9E9
}
.font-size-10 {
    font-size: 10px;
}
.font-size-12 {
    font-size: 12px;
}
.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}
.font-weight-700 {
    font-weight: 700;
}

.cursor-pointer {
    cursor: pointer;
}

.color-white {
    color: #fff;
}

.color-green {
    color: #0A4225;
}

.color-green-fill {
    color: #19A85E;
}

.color-gray {
    color: #7D7D7D;
}

.btn-defult {
    text-decoration: none;
    transition: 0.3s;
}

.btn-defult:hover {
    color: #fff;
    opacity: .7;
}

.btn-submit {
    color: #fff;
    background: #19A85E;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-submit:hover {
    border: 1px solid #19A85E;
    background: #fff;
}

.a-default {
    text-decoration: none;
    color: #212529;
    transition: 0.3s;
}

.a-default:hover {
    text-decoration: none;
    opacity: .6;
    color: #212529;
}

.btn-default {
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-default:hover {
    opacity: .7;
}

.btn-border {
    border: 1px solid #19A85E;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-border:hover {
    color: #fff;
    background: #19A85E;
}

.sh-pass {
    position: absolute;
    right: 25px;
    bottom: 18px;
}

.input-border {
    border-top: unset;
    background: unset;
    border-left: unset;
    border-right: unset;
    box-sizing: unset;
    border-bottom: 1px solid #F2F2F2;
    font-size: 12px;
    width: 100%;
}

.input-border:focus {
    border-color: #0A4225;
    /*    box-shadow: 0 0 0 0.1rem rgb(10 66 37 / 25%);*/
    border-bottom: 1px solid #07331C;
    outline: none;
}

.input-custom {
    border-top: unset;
    background: #F5F5F5;
    border-left: unset;
    border-right: unset;
    box-sizing: inherit;
    border-bottom: 1px solid #F2F2F2;
    /* font-size: 12px; */
    padding: 15px;
    width: 100%;
}

.input-custom:focus {
    border-color: #0A4225;
    box-shadow: unset;
    border-bottom: unset;
    background: #F5F5F5;
    outline: none;
}

.select-custom {
    border-top: unset;
    background-color: #F5F5F5;
    border-left: unset;
    border-right: unset;
    box-sizing: inherit;
    border-bottom: 1px solid #F2F2F2;
    /* font-size: 12px; */
    padding: 15px;
    width: 100%;
}

.select-custom:focus {
    border-color: #0A4225;
    box-shadow: unset;
    border-bottom: unset;
    background-color: #F5F5F5;
    outline: none;
}

.bg-no{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
}

.text-topic {
    font-size: 42px;
    font-weight: 700;
}
.box-social{
    display: block;
    background-color: #F5F5F5;
    border-radius: 12px;
    padding: 15px;
    text-decoration: none;
    color: #000000;
    transition: 0.3s;
    margin-bottom: 15px;
}
.box-social:hover{
    background-color: #F7CE0E;
    text-decoration: none;
    color: #000000;
    margin-bottom: 30px;
    box-shadow: 0px 20px 30px -15px #F7CE0E;
}